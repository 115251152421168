import React from 'react'
import Image from 'components/common/Image'

const KeyFeatures = ({data}) => {
    const renderKeyFeature = ({title, text, image}, index) => {
        return (
            <div key={index} className="KeyFeature">
                <div className="image"><Image src={image} /></div>
                <div className="texts">
                    <div className="title">{title}</div>
                    <div className="text">{text}</div>
                </div>
            </div>
        )
    }
    const renderKeyFeatures = (keyFeatures) => {
        return keyFeatures.map(renderKeyFeature)
    }
    return (
        <div className="KeyFeatures">
            {renderKeyFeatures(data)}
        </div>
    )
}

export default KeyFeatures
