import React from 'react'
import PropTypes from 'prop-types';

const Image = ({src, alt, className}) => {
    return (
        <div className={`Image ${className}`} >
            <img loading="lazy" src={src} alt={alt} />
        </div>
    )
}

Image.propTypes = {
    className: PropTypes.string
}
Image.defaultProps = {
    className: ''
}

export default Image
