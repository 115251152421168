import React from 'react';

const Background = () => {
    return (
        <div className="Background">
            <div className="hexagon-base-glow hexagon-base-glow-1"></div>
            <div className="hexagon-base-glow hexagon-base-glow-2"></div>
            <div className="hexagon-base"></div>
        </div>
    )
}

export default Background
