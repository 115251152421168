/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
// import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import './style.scss';
import Logo from 'images/logo.svg';
import SocialLinks from 'components/common/SocialLinks';

const Header = ({menuOnClickHandler, menuStatus, headerStatus}) => {
    return (
        <header id="Header" className={headerStatus}>
            <div className="background"></div>
            <div className="content">
                <div className="logoBar">
                    <a href="/#Home"><img src={Logo} alt="Mercury10" /></a>
                </div>
                <div className="menuBar">
                    <SocialLinks />
                    <div className={`menuBtn ${(menuStatus)?'activeMenu':'inactiveMenu'}`}>
                        <button className="activeMenuButton" onClick={()=>menuOnClickHandler()}><FontAwesomeIcon icon={faBars} /></button>
                        <button className="inactiveMenuButton" onClick={()=>menuOnClickHandler()}><FontAwesomeIcon icon={faTimes} /></button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
