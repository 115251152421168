import React from 'react'
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import './style.scss'
import { connect } from 'react-redux';

const SectionsLinks = ({direction, sectionOnClickHandler, routes}) => {
    const { t } = useTranslation()

    return (
        <div className={`SectionsLinks ${direction}`}>
            <ul>
                <li><a onClick={()=>sectionOnClickHandler()} href="#Home">{ t('SectionsLinks.Home') }</a></li>
                <li><a onClick={()=>sectionOnClickHandler()} href="#Uses">{ t('SectionsLinks.Uses') }</a></li>
                <li><a onClick={()=>sectionOnClickHandler()} href="#TopFeatures">{ t('SectionsLinks.TopFeatures') }</a></li>
                <li><a onClick={()=>sectionOnClickHandler()} href="#SuccessStories">{ t('SectionsLinks.SuccessStories') }</a></li>
                <li><a onClick={()=>sectionOnClickHandler()} href="#Constellation">{ t('SectionsLinks.Constellation') }</a></li>
                <li><a onClick={()=>sectionOnClickHandler()} href="#FullFeatures">{ t('SectionsLinks.FullFeatures') }</a></li>
                <li><a href={routes.downloads}>{ t('SectionsLinks.Downloads') }</a></li>
                <li><a href={routes.login} onClick={()=>{window.location=routes.login}} >Login</a></li>
            </ul>
        </div>
    )
}
    
SectionsLinks.propTypes = {
    direction: PropTypes.string,
    sectionOnClickHandler: PropTypes.func
}
SectionsLinks.defaultProps = {
    direction: 'row',
    sectionOnClickHandler: ()=>{}
}
const mapStateToProps = state =>{
    return {
        routes: state.routes
    }
}
export default connect(mapStateToProps)(SectionsLinks)
