import React from 'react';

const View = ({viewName, view}) => {
    const renderComponent = (component) =>{
        const Comp = component;
        return <Comp />
    }
    return (
        <div id="rootWrapper">
            {renderComponent(view)}
        </div>
    )
}

export default View
