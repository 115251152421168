import React from 'react'
import SliderButtons from 'components/common/SliderButtons'
import SliderMobileControl from 'components/common/SliderMobileControl'

// import Diagonal from './../../assets/Slider/diagonal-1.png'

const Control = ({controlAction}) => {
    return (
        <div className="Control">
            {/* <div className="diagonal">
                <img src={Diagonal} alt="Control Diagonal" />
            </div> */}
            <SliderButtons
                previousButtonClickHandler={() => controlAction("previous")}
                nextButtonClickHandler={() => controlAction("next")}
            />
            <SliderMobileControl
                previousButtonClickHandler={() => controlAction("previous")}
                nextButtonClickHandler={() => controlAction("next")}
            />
        </div>
    )
}

export default Control
