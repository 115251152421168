import React from 'react';
import './style.scss';

const Button = ({children, onClickHandler}) => {
    return (
        <div className="Button" onClick={()=>{onClickHandler()}}>
            <div className="body">
                {children}
            </div>
        </div>
    )
}

export default Button
