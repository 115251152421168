import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft,faCaretRight  } from '@fortawesome/free-solid-svg-icons';
import './style.scss';

const SliderButtons = ({previousButtonClickHandler, nextButtonClickHandler}) => {
    return (
        <div className="SliderButtons">
            <button 
                className="previous"
                
                onClick={()=>{previousButtonClickHandler("Prev")}}
            >
                <FontAwesomeIcon icon={faCaretLeft} />
            </button>
            <button 
                className="next"
                onClick={()=>{nextButtonClickHandler("Next")}}
            >
                <FontAwesomeIcon icon={faCaretRight} />
            </button>
        </div>
    )
}

export default SliderButtons
