import React from 'react';

const Background = () => {
    return (
        <div className="Background">
            <div className="floatingObject spot1"></div>
            <div className="floatingObject spot2"></div>
            <div className="floatingObject spot3"></div>
            <div className="floatingObject spot4"></div>
            <div className="floatingObject spot5"></div>
            <div className="floatingObject spot6"></div>

            <div className="floatingObject texture"></div>
        </div>
    )
}

export default Background
