import React, { useEffect } from 'react'
import { connect } from 'react-redux'


const Booter = ({children, loadRoutes}) => {
    useEffect(
        () => {
            fetch("./assets/routes.json").then(function (res) {
                return res.json()
            }).then(function (data) {
                loadRoutes(data)
                
            }).catch(
                function (err) {
                    console.log(err, ' error')
                }   
            )
        }
    )

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}
const mapDispatchToProps = dispatch => {
    return({
        loadRoutes: (routes) => {
            dispatch({
                type: 'LOAD_ROUTES',
                payload: routes
            })
        }
    });
}


export default connect(false, mapDispatchToProps)(Booter)
