import React from 'react';
import Background from './Background.jsx';
import ContentV2 from './ContentV2/index.jsx';
import './style.scss';

const Home = () => (
    <section id="Home"> 
        <Background />
        <ContentV2 />
    </section>
)

export default Home
