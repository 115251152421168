import React from 'react'
import { Provider } from 'react-redux'
import store from 'redux/store'
import 'i18n';
import { BrowserRouter as Router } from "react-router-dom"
import RouterSwitcher from 'components/router/RouterSwitcher';
import Booter from './Booter'
import './App.scss';

function App({loadRoutes}) {
    return (
        <Provider store={store}>
            <Booter>
                <main id="App">
                    <Router>
                        <RouterSwitcher />
                    </Router>
                </main>
            </Booter>
        </Provider>
    );
}

export default App;
