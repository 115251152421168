import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import 'auxiliar'
import Background from './Background';
import Control from './Control';
import Slides from './Slides';
import './style.scss'

const HexagonSlider = ({ slides, sliderHomeAutoplay }) => {
    const autoplayManualOverride = false
    
    const sliderLenght = slides.length
    const [activeSlide, setActiveSlide] = useState(0)
    useEffect(
        () => {
            if(sliderHomeAutoplay && autoplayManualOverride){
            // if(false){
                const interval = setInterval(() => { //assign interval to a variable to clear it.
                    setActiveSlide((activeSlide + 1)%sliderLenght)
                }, 5000)
    
                return () => clearInterval(interval); //This is important
            }

        },
        [activeSlide, sliderLenght, sliderHomeAutoplay, autoplayManualOverride]
    )
    
    const controlAction = action => {
        switch (action) {
            case "previous":
                setActiveSlide((activeSlide-1).mod(slides.length))    
            break;
            
            case "next":
                setActiveSlide((activeSlide+1).mod(slides.length))
            break;
            
            default:break;
        }
    }
    return (
        <div className="HexagonSlider">
            <div className="wrapper">
                <Background />
                <Slides slides={slides} activeSlide={activeSlide} />
                <Control controlAction={controlAction} />            
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        sliderHomeAutoplay: state.sliderHomeAutoplay
    }
}
export default connect(mapStateToProps, false)(HexagonSlider)
