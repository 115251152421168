import React from 'react'
import SectionsLinks from 'components/common/SectionsLinks'
import SocialLinks from 'components/common/SocialLinks'

const Menu = ({itemOnClickHandler}) => {
    return (
        <div className="Menu">
            <SectionsLinks direction="column" sectionOnClickHandler={itemOnClickHandler} />
            <SocialLinks />
        </div>
    )
}

export default Menu
