import React from 'react'
import { Switch, Route } from "react-router-dom";
import View from 'components/router/View'
import Main from 'components/views/Main'


const RouterSwitcher = () => (
    <Switch>
        <Route path="/">
            <View viewName="Home" view={Main} />
        </Route>
    </Switch>
)

export default RouterSwitcher
