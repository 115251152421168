import { createStore } from 'redux';

// Redux
const stateInitialValue = {
    sliderHomeAutoplay: true,
    sliderHomeVideoPlayerStatus: false,
    videoToLoad: '',
    popUpContent: ()=><span>nada</span>,
    popUpStatus: false,
    routes: {
        "downloads":        "https://mercury10.com/mws/dashboard.php",
        "login":            "https://mercury10.com/mws/dashboard.php",
        "downloadIDE":     "https://mercury10.com/mws/dashboard.php",
        "disclaimer":       "https://mercury10.com/disclaimer.html",
        "cookiePolicy":    "https://mercury10.com/cookie-policy.html",
        "returnPolicy":    "https://mercury10.com/mws/dashboard.php",
        "policyPolicy":    "https://mercury10.com/privacy-policy.html"
    }
}
const reducer = (state = stateInitialValue, action) => {
    switch(action.type){
        case 'LOAD_ROUTES':
            return {
                ...state,
                routes: action.payload
            }

        case 'LOAD_VIDEO':
            return {
                ...state,
                videoToLoad: action.payload.videoId,
                sliderHomeVideoPlayerStatus: !state.sliderHomeVideoPlayerStatus,
                sliderHomeAutoplay: false,
            }
        case 'UNLOAD_VIDEO':
            return {
                ...state,
                videoToLoad: '',
                sliderHomeVideoPlayerStatus: false,
                sliderHomeAutoplay: true
            }
        case 'LOAD_POPUP':
            return {
                ...state,
                popUpContent: action.payload.data,
                popUpStatus: true,
                sliderHomeAutoplay: false
            }
        case 'CLOSE_POPUP':
            return {
                ...state,
                popUpContent: ()=><span>nada</span>,
                popUpStatus: false,
                sliderHomeAutoplay: true
            }
        
        default:
            return state;
    }
}
const store = createStore(
    reducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store