import React from 'react';
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faPlus, faSignInAlt  } from '@fortawesome/free-solid-svg-icons';
import HexagonSlider from 'components/common/HexagonSlider';
import Image from 'components/common/Image';
import './style.scss'
import Slide0Img from './../assets/Slider/Slides/slide-0.png'
import Slide1Img from './../assets/Slider/Slides/slide-1.png'
import Slide2Img from './../assets/Slider/Slides/slide-2.png'
import Slide3Img from './../assets/Slider/Slides/slide-3.png'
import Slide0Tag from './../assets/Slider/Slides/slide-0-tag.png'
import Slide1Tag from './../assets/Slider/Slides/slide-1-tag.png'
import Slide2Tag from './../assets/Slider/Slides/slide-2-tag.png'
import Slide3Tag from './../assets/Slider/Slides/slide-3-tag.png'
import KF1 from './../assets/KeyFeatures/kf-1.png'
import KF2 from './../assets/KeyFeatures/kf-2.png'
import KF3 from './../assets/KeyFeatures/kf-3.png'
import KickstarterLogo from './../assets/kickstarter-logo-white.png'
import KeyFeatures from './KeyFeatures';
import Mailchimp from 'react-mailchimp-form'



const ContentV2 = () => {
    const commingSoonStyle={
        marginBottom:'20px'
    }

    const commingSoonStyle1={
        marginBottom:'10px'
    }

    const { t } = useTranslation()
    const slides = [
        {
            name:           "M10 Main Board",
            mobileTitle:    () => <span>M10 Main Board</span>,
            image:          Slide0Img,
            width:          578,
            height:         372,
            tagImage:       Slide0Tag,
            button:         {
                buttonText:     "Watch Video",
                buttonIcon:     <FontAwesomeIcon icon={faCaretRight} />,
                buttonAction:   "VIDEO",
                buttonVideo: {
                    channel: 'youtube',
                    videoId: 'LlhmzVL5bm8'
                }
            },
        },
        {
            name:           "TouchScreen",
            mobileTitle:    () => <span>TouchScreen</span>,
            image:          Slide1Img,
            width:          578,
            height:         372,
            tagImage:       Slide1Tag,
            button:         {
                buttonText:     "Watch Video",
                buttonIcon:     <FontAwesomeIcon icon={faCaretRight} />,
                buttonAction:   "VIDEO",
                buttonVideo: {
                    channel: 'youtube',
                    videoId: 'x6cTpJozRd0'
                }
            },
        },
        {
            name:           "GPS LoRa Shield",
            mobileTitle:    () => <span>GPS and LoRa Shield</span>,
            image:          Slide2Img,
            width:          578,
            height:         372,
            tagImage:       Slide2Tag,
            button:         {
                buttonText:     "MORE INFO",
                buttonIcon:     <FontAwesomeIcon icon={faPlus} />,
                buttonAction:   "POPUP",
                buttonActionData: () => (
                    <React.Fragment>
                        <h1>GPS/LoRa shield</h1>
                        <ul>
                            <li>12 to 5 V. power supply for Mercury10 Board.</li>
                            <li>NEO-6M U-Box GPS module.</li>
                            <li>50 ohm external GPS antenna connector.</li>
                            <li>Over distance Serial Port, up to 200 meters at 9600 baud rate.</li>
                            <li>LoRa connecting Port.</li>
                            <li>JoyStick connecting Port.</li>
                            <li>Built-in indicator LEDs.</li>
                            <li>External indication LED connection port.</li>
                        </ul>
                    </React.Fragment>
                )
            },
        },
        {
            
            name:           "Osciloscope & Multimeter",
            mobileTitle:    () => <span>Osciloscope &amp; Multimeter</span>,
            image:          Slide3Img,
            width:          578,
            height:         372,
            tagImage:       Slide3Tag,
            button:         {
                buttonText:     "MORE INFO",
                buttonIcon:     <FontAwesomeIcon icon={faPlus} />,
                buttonAction:   "POPUP",
                buttonActionData: () => (
                    <React.Fragment>
                        <h1>Multimeter &amp; Oscillocope</h1>
                        <h3>Multimeter</h3>
                        <ul>
                            <li>Digital auto selector (Voltimeter, Ohmmeter, Ammeter, Osciloscope).</li>
                            <li>Automatically detects and measures AC/DC voltages.</li>
                            <li>Automatically detects and measures AC/DC current.</li>
                            <li>High current measurement port - rated to 30 Amp.</li>
                            <li>Auto Ranging.</li>
                            <li>Ohmemeter 0 to 10 M Ohms with high precision.</li>
                            <li>Volt meter ratings 0-700 VDC and 0-500 VAC (rms).</li>
                            <li>Ammeter is capable of measuring 0-30A (DC/AC), line voltage can be up to 2500 V peak</li>
                        </ul>
                        <h3>Oscillocope</h3>
                        <ul>
                            <li>Input signal up to 30V peak and maximun frequency of 1Mhz bandwith.</li>
                            <li>Auto Ranging.</li>
                        </ul>
                        <h3>Shield</h3>
                        <ul>
                            <li>Shield input power 12V, regulates power to Mercury10 board.</li>
                        </ul>
                    </React.Fragment>
                )
            },
        },
    ];
    const keyFeatures = [
        {
            title: <>Compact and <br/>powerful</>,
            text: <>Leading IoT solutions <br/>in power and price.</>,
            image: KF1
        },
        {
            title: <>Easy and funny <br/>programming</>,
            text: <>With JavaScript <br/>or Python.</>,
            image: KF2
        },
        {
            title: <>All-in-one <br/>real solution</>,
            text: <>Adaptable ecosystem <br/>for industry or hobbyists.</>,
            image: KF3
        },
    ]
    return (
        <div className="ContentV2">
            <div className="comingSoon onHeader">
                <div className="text">{ t('Home.V2.text-comingSoon') }</div>
                <div className="image"><Image src={KickstarterLogo} /></div>
            </div>
            <div className="cover">
                <div className="text header">
                    <div className="title">{ t('Home.V2.header.title.1') }<b>{ t('Home.V2.header.title.2') }</b></div>
                    <div className="subtitle">{ t('Home.V2.header.subtitle') }</div>
                    <div className="comingSoon onBody" style={commingSoonStyle}>
                        <div className="text" style={commingSoonStyle1}>{ t('Home.V2.text-comingSoon') }</div>
                        <div className="image"><Image src={KickstarterLogo} /></div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <HexagonSlider slides={slides} />
                    </div>
                    <div className="column">
                        <KeyFeatures data={keyFeatures} />
                        <div className="signUpForm">
                            <div className="header">
                                <div className="title">Sign up now</div>
                                <div className="subtitle">Be an earlybird and get a discount when we launch.</div>
                            </div>
                            <div className="form">
                                <Mailchimp
                                    className="MailchimpSimpleForm"
                                    action={'https://mercury10.us1.list-manage.com/subscribe/post?u=9c228e54140491fa6486172b0&amp;id=8ceb81223c'}
                                    fields={[
                                        {
                                            name: 'EMAIL',
                                            placeholder: 'Email',
                                            type: 'email',
                                            required: true
                                        }
                                    ]}
                                    messages = {
                                        {
                                          sending: "Sending...",
                                          success: "Thank you for subscribing!",
                                          error: "An unexpected internal error has occurred.",
                                          empty: "You must write an e-mail.",
                                          duplicate: "Too many subscribe attempts for this email address",
                                          button: <><FontAwesomeIcon className="icon" icon={faSignInAlt} /> SignUp</>
                                        }
                                      }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentV2
