import React from 'react';
import Slide from './Slide';
import 'auxiliar'

const Slides = ({slides, activeSlide}) => {
    const renderSlides = () => {
        const initialClasses = {
            [(activeSlide-1).mod(slides.length)]:"leftDisplace",
            [activeSlide]: "active",
            [(activeSlide+1).mod(slides.length)]:"rightDisplace"
        }
        const renderSlide = (slide, index) => {
            return(
                <Slide
                    width={slide.width}
                    height={slide.height}
                    image={slide.image}
                    tagImage={slide.tagImage}
                    name={slide.name}
                    mobileTitle={slide.mobileTitle}
                    button={slide.button}
                    className={initialClasses[index]}
                    key={index.toString()}
                />
            )
        }
        return slides.map(renderSlide);
    }

    return (
        <div className="Slides" activeslide={activeSlide}>
            {renderSlides()}
        </div>
    )
}

export default Slides
