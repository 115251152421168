import React from 'react';
import Image from './Image';

const Slide = ({width, height, image, name, mobileTitle, className }) => {
    return (
        <div className={`Slide ${(className!==undefined)?className:''}`}>
            <Image src={image} alt={name} widht={width} height={height} />
            <div className="title">{mobileTitle()}</div>
        </div>
    )
}

export default Slide