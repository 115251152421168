import React from 'react'
import { connect } from 'react-redux'
import ModalVideo from 'react-modal-video'
import Menu from './Menu'
import './style.scss'
import Button from 'components/common/Button'

const Overall = ({menuStatus, backgroundOnClickHandler, videoToLoad, sliderHomeVideoPlayerStatus, unloadVideo, popUpClose, popUpContent, popUpStatus}) => {
    const renderPopUpContent = (popUpStatus, popUpContent) => {
        if(popUpStatus){
            return(
                <div className="PopUp">
                    {popUpContent()}
                    <center><Button onClickHandler={()=> popUpClose()}>Close</Button></center>
                </div>
            )
        }
    }
    const renderVideoPlayer = (status) => {
        if(status){
            return(
                <ModalVideo channel='youtube' autoplay isOpen={sliderHomeVideoPlayerStatus} videoId={videoToLoad} onClose={() => unloadVideo()} />
            )
        }
    }
    return (
        <section
            id="Overall"
            className={`
                ${(menuStatus) ? 'menuActive' : 'menuInactive'} 
                ${(sliderHomeVideoPlayerStatus) ? 'videoActive' : 'videoInactive'} 
                ${(popUpStatus) ? 'popUpActive' : 'popUpInactive'} 
            `}>
            <div className="background" onClick={
                ()=>{
                    unloadVideo();
                    backgroundOnClickHandler()
                }
                
            }></div>
            <div className="content">
                <Menu itemOnClickHandler={backgroundOnClickHandler} />
                {renderPopUpContent(popUpStatus, popUpContent)}
                {renderVideoPlayer(sliderHomeVideoPlayerStatus)}
            </div>
        </section>
    )
}
const mapStateToProps = state => {
    return {
        sliderHomeAutoplay: state.sliderHomeAutoplay,
        videoToLoad: state.videoToLoad,
        sliderHomeVideoPlayerStatus: state.sliderHomeVideoPlayerStatus,
        popUpContent: state.popUpContent,
        popUpStatus: state.popUpStatus
    }
}
const mapDispatchToProps = dispatch => {
    return({
        unloadVideo: (value) => {
            dispatch({
                type: 'UNLOAD_VIDEO',
                payload: value
            })
        },
        popUpClose: () => {
            dispatch({
                type: 'CLOSE_POPUP',
                payload: {
                    popUpContent: ()=>false,
                    popUpStatus: false
                }
            })
        }
    });
}

export default connect(mapStateToProps, mapDispatchToProps)(Overall)
