import React, { Suspense, useState, useEffect } from 'react'
import Header from "components/sections/Header";
import Home from "components/sections/Home";
import Overall from 'components/sections/Overall';
const Uses = React.lazy(() => import('components/sections/Uses'));
const TopFeatures = React.lazy(() => import('components/sections/TopFeatures'));
const Banner1  = React.lazy(() => import('components/sections/Banner1'));
const Comparison  = React.lazy(() => import('components/sections/Comparison'));
const Banner3  = React.lazy(() => import('components/sections/Banner3'));
const SuccessStories  = React.lazy(() => import('components/sections/SuccessStories'));
const Constellation  = React.lazy(() => import('components/sections/Constellation'));
const FullFeatures  = React.lazy(() => import('components/sections/FullFeatures'));
const Banner2  = React.lazy(() => import('components/sections/Banner2'));
const Footer  = React.lazy(() => import('components/sections/Footer'));

const Main = () => {
    const [menuStatus, setmenuStatus] = useState(false);
    const toggleMenu = () => {
        setmenuStatus(!menuStatus);
    }

    const [headerStatus, setHeaderStatus] = useState('inactive');
    useEffect(
        () => {
            document.addEventListener("scroll", () => {
                if(headerStatus === 'inactive' && window.pageYOffset > 150){
                    setHeaderStatus('active')
                }else if(headerStatus === 'active' && window.pageYOffset <= 150){
                    setHeaderStatus('inactive')
                    setmenuStatus(false)
                }
            });
        },
        [headerStatus]
    );

    const backgroundOnClickHandler = () => {
        setmenuStatus(false);
    }
    return (
        <Suspense fallback={null}>
            <Header
                menuOnClickHandler={toggleMenu}
                menuStatus={menuStatus}
                headerStatus={headerStatus}
            />
            <Home />
            <Suspense fallback={<div>Loading...</div>}>
                <Uses />
                <TopFeatures />
                <Banner1 />
                <Comparison />
                <Banner3 />
                <SuccessStories />
                <Constellation />
                <FullFeatures />
                <Banner2 />
                <Footer />
            </Suspense>
            <Overall menuStatus={menuStatus} backgroundOnClickHandler={backgroundOnClickHandler} />
        </Suspense>
    )
}

export default Main
