/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookSquare, faTwitter } from "@fortawesome/free-brands-svg-icons";
import './style.scss';

const SocialLinks = ({routes}) => {
    return (
        <div className="SocialLinks">
            <ul>
                <li><a href={routes.instagram}><FontAwesomeIcon icon={faInstagram} /></a></li>
                <li><a href={routes.facebook}><FontAwesomeIcon icon={faFacebookSquare} /></a></li>
                <li><a href={routes.twitter}><FontAwesomeIcon icon={faTwitter} /></a></li>
            </ul>
        </div>
    )
}
const mapStateToProps = state =>{
    return {
        routes: state.routes
    }
}
export default connect(mapStateToProps)(SocialLinks)
